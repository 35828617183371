<template>
  <div class="checkout-form">

    <form class="form grey lighten-2 px-2 pt-2" @submit="submit" ref="paymentForm">

      <div class="form-row pb-2">
        <input id="email-element" type="email" placeholder="Receipt email" required="" autocomplete="email" ref="emailElement">
      </div>

      <div class="form-row pb-2">
        <div id="card-element" ref="cardElement"></div>
      </div>

      <!-- Used to display form errors. -->
      <div role="alert" ref="cardErrors"></div>

      <button style="display:block; margin:auto;">
        <v-btn color="info">

          <span v-if="!processing">Checkout</span>
          <span v-else>
            <v-progress-circular
              indeterminate
              :size="28"
              color="white"
            ></v-progress-circular>
          </span>

        </v-btn>
      </button>

      <div class="text-xs-center pt-2">We currently support only <span class="body-2">Visa</span> and <span class="body-2">MasterCard</span>.</div>
      <div class="text-xs-center">
        <img src="/images/3dsecure.png" width="170" />
      </div>
      <div class="text-xs-center pt-2 grey--text">We have a $300 per-checkout limit and 3 checkouts per day.</div>

      <div class="pos-rel pt-2 mb-3">
        <div class="line-bottom">

        </div>
      </div>

      <div class="text-xs-center red--text text--darken-3">Purchases are non-refundable.</div>
      <div class="text-xs-center grey--text">By entering payment information, you agree to our terms and conditions.</div>
      <div class="text-xs-center grey--text">You will receive a payment receipt email from Stripe.</div>

    </form>

  </div>
</template>

<script>
import {elements} from '@/libs/stripe'
import {style} from '@/static/stripe-elements'

export default {
  name:'checkout-form',
  components: {
  },
  props: {
    total:{type:Number, default:20},
  },
  data() {
    return {
      card: null,
      form: null,
    }
  },
  computed: {

    processing() {
      return this.$store.getters.checkoutProcessing;
    },

  },
  mounted() {

    // Create a card Element. Mount to div. Handle change events.
    this.card = elements.create('card', {style: style});
    this.card.mount(this.$refs.cardElement);
    this.card.addEventListener('change', this.onCardChange);

  },
  beforeDestroy() {
    this.card.removeEventListener('change', this.onCardChange);
    this.card.unmount();
    this.card.destroy();
  },
  methods: {

    onCardChange(event) {
      if (event.error)
        this.$refs.cardErrors.textContent = event.error.message;
      else
        this.$refs.cardErrors.textContent = '';
    },

    submit(event) {
      event.preventDefault();
      this.$emit('submit', {
        cardElement:this.card,
        emailElement:this.$refs.emailElement,
      });
    },

  }
}
</script>

<style scoped>

.form {
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
}

.StripeElement {
  background-color: white;
  padding: 11px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

input {
  width: 100%;
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;

  fontFamily: "Helvetica Neue", Helvetica, sans-serif;
  color: #32325d;
  font-size: 16px;
  line-height: 16px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  outline: none;
}
::placeholder {
  color: #aab7c4;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

</style>
