<template>

  <v-card class="mb-2">

    <div class="d-flex" style="overflow:hidden;">

      <div style="flex:0 0 0px;" class="cart-item-image">
        <img :src="item.screenshot" class="cart-item-image" style="display:block;" />
      </div>

      <div style="flex:0 0 0px; overflow:hidden;">


        <v-layout fill-height>

          <!-- content section -->
          <v-flex xs4 class="pos-rel" style="min-width:60px;">

            <v-layout fill-height column>

              <v-flex class="d-flex" align-center>

                <div>


                  <div v-if="contentURL">

                    <div class="text-xs-center subheading">
                      <a :href="contentURL" target="_blank">{{contentText}}</a>
                    </div>
                    <div v-show="showContentCaption" class="text-xs-center caption">
                      <a :href="contentURL" target="_blank">{{contentCaption}}</a>
                    </div>

                  </div>
                  <div v-else>

                    <div class="text-xs-center subheading">
                      {{contentText}}
                    </div>
                    <div v-show="showContentCaption" class="text-xs-center caption">
                      {{contentCaption}}
                    </div>

                  </div>


                </div>

              </v-flex>

            </v-layout>

            <div class="line-side"></div>
          </v-flex>

          <!-- delivery -->
          <v-flex xs8 class="pos-rel" style="min-width:0;">
            <!-- <div class="text-xs-center cart-item-section-title">Delivery</div> -->

            <div class="rel-container" style="overflow:hidden;">

              <v-layout fill-height column>

                <v-flex class="d-flex" align-center>

                  <div>

                    <div v-if="deliveryType === 'link'">

                      <div class="text-xs-center subheading">
                        Link Delivery
                      </div>
                      <div v-show="showContentCaption" class="text-xs-center caption">
                        A Link will be generated for you to send
                      </div>

                    </div>
                    <div v-else>

                      <v-layout>
                        <v-flex class="delivery-title">To:</v-flex>
                        <v-flex class="delivery-value">{{item.receiverEmail}}</v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex class="delivery-title">On:</v-flex>
                        <v-flex class="delivery-value">
                          {{deliveryDate}}<br />
                          {{deliveryTime}}<br />
                          <span class="delivery-caption">{{this.deliveryTimezone}}</span>
                        </v-flex>
                      </v-layout>

                    </div>


                  </div>

                </v-flex>

              </v-layout>

            </div>

            <div class="line-side"></div>

          </v-flex>

        </v-layout>


      </div>

      <div style="flex:0 0 0px;" class="cart-item-amount">

        <v-layout fill-height align-center>

          <div class=" w-100 text-xs-right">
            {{totalAmountString}}
          </div>

        </v-layout>

      </div>

    </div>

  </v-card>

</template>

<script>
import moment from 'moment-timezone'

export default {
  name:'cart-item',
  props: {
    item: {type:Object, default:null},
  },
  computed: {

    deliveryType() { return this.item.deliveryType; },

    deliveryDate() {
      if(this.item.deliveryTime) {
        return moment(this.item.deliveryTime).format('L');
      }else{
        return 'Send now';
      }
    },

    deliveryTime() {
      if(this.item.deliveryTime) {
        return moment(this.item.deliveryTime).format('LT');
      }else{
        return null;
      }
    },

    deliveryTimezone() {
      if(this.item.deliveryTime) {
        return this.item.deliveryTimezone;
      }else{
        return null;
      }
    },

    // ------------------------------------------ //

    contentType() {
      if(this.item) {
        return this.item.contentType;
      }
      return null;
    },

    contentAmount() {
      if(this.item) {
        if(this.item.contentInfo) {
          if(this.item.contentInfo.amount) {
            return this.item.contentInfo.amount;
          }
        }
      }
      return 0;
    },

    totalAmount() {
      let amount = 0;
      if(this.item.customizationMode !== 'free') { amount += 0.99; }
      amount += this.contentAmount;
      return amount;
    },

    totalAmountString() {
      if(this.totalAmount > 0.000001) {
        return '$' + this.totalAmount;
      }else{
        return 'free';
      }
    },

    url() {
      if(this.item) {
        if(this.item.contentInfo) {
          if(this.item.contentInfo.url) { return this.item.contentInfo.url; }
          if(this.item.contentInfo.file) { return this.item.contentInfo.file.url; }
        }
      }
      return null;
    },

    fileName() {
      if(this.item) {
        if(this.item.contentInfo) {
          if(this.item.contentInfo.file) { return this.item.contentInfo.file.name; }
        }
      }
      return null;
    },

    contentText() {
      switch (this.contentType) {
        case 'tango-card': return '$' + this.contentAmount;
        case 'pdf': return 'PDF';
        case 'link': return (this.contentURL) ? 'Link' : 'Swipewrap';
        case 'image': return 'Image';
      }
    },

    contentURL() {
      switch (this.contentType) {
        case 'tango-card': return null;
        case 'link':
        case 'pdf':
        case 'image': return this.url;
      }
    },

    contentCaption() {
      if(this.item) {
        if(this.item.contentInfo) {
          switch (this.item.contentType) {
            case 'tango-card': return 'Tango Card';
            case       'link': return this.url;
            case        'pdf':
            case      'image': return this.fileName;
          }
        }
      }
      return null;
    },

    showContentCaption() {
      if(this.contentType === 'tango-card') {
        return true;
      }
      return this.$vuetify.breakpoint.width > 700;
    },

  },
  mounted() {
    // debug
    // console.log( this.item );
  },
}
</script>

<style scoped>

.line-side {
  position: absolute;
  top: 6%;
  bottom: 6%;
  right: 0px;
  width: 1.5px;
  background-color: lightgrey;
}

/* ************************* */

.cart-item-image {
  width:100px;
  max-width: 100px;
  min-width: 100px;
}

.cart-item-amount {
  width:100px;
  max-width: 100px;
  min-width: 100px;
  padding-right: 20px;
}

/* ************************* */

.cart-item-section-title {
  font-size: 11px;
}

/* ************************* */

.delivery-title {
  min-width:80px;
  max-width:80px;
  text-align: right;
  padding-right: 4px;
  font-weight: 600;
  font-size: 12px;
}

.delivery-value {
  font-size: 12px;
}

.delivery-caption {
  font-size: 9px;
}

/* ************************* */

.card-title {
  min-width:45px;
  max-width:45px;
  text-align: right;
  padding-right: 4px;
  font-weight: 600;
  font-size: 10px;
}

.card-value {
  font-size: 10px;
}

/* ************************* */

/* mobile */
@media only screen and (max-width: 700px) {

  .cart-item-image {
    width:72px;
    max-width:72px;
    min-width: 72px;
  }

  .delivery-title {
    min-width:25px;
    max-width:25px;
  }

  .cart-item-amount {
    width:53px;
    max-width: 53px;
    min-width: 53px;
    padding-right: 2px;
  }

}

</style>
