<template>
  <div class="cart">
    <main-nav></main-nav>
    <v-content>
      <v-container class="pb-5">

        <div v-if="numItems == 0" class="text-xs-center headline" style="padding-top: 24px; padding-bottom: 12px; color: #aaaaaa">
          Your Cart is Empty
        </div>

        <!-- items  -->
        <div v-for="(item, i) in items" :key="i" class="pos-rel">

          <cart-item :item="item"></cart-item>

          <v-menu offset-y class="pos-abs" style="top:-10px; right:-13px;">

            <v-btn slot="activator" icon class="px-1" color="red white--text" style="width:15px; height:15px;">
              <v-icon style="font-size:14px;">clear</v-icon>
            </v-btn>
            <v-list class="pa-0">
              <v-list-tile @click="$store.dispatch('removeCartItem', i)">
                <v-list-tile-title>Remove Item</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>

        </div>

        <!-- totals  -->
        <div v-show="total > 0.000001 || numItems > 0">

          <div class="text-xs-right">
            <table style="margin-left:auto; margin-right:0;">
              <tr> <td align="right">${{startTotal}}              </td> <td>: Start Total</td> </tr>
              <tr> <td align="right">${{creditsDeduction}}        </td> <td>: Credits Deduction</td> </tr>
              <tr> <td align="right">${{firstGiftTokenDeduction}} </td> <td>: First Gift Token</td> </tr>
              <tr> <td align="right">${{afterTotal}}              </td> <td>: After Total</td> </tr>
              <tr> <td align="right">${{stripeFee}}               </td> <td>: Payment Processing</td> </tr>
              <tr> <td align="right">${{wrapTotal}}               </td> <td>: Wrap Total</td> </tr>
              <tr> <td align="right">${{processingFee}}           </td> <td>: Net Processing Fee</td> </tr>

              <tr> <td style="height:3px;">                             </td> <td> </td> </tr>
              <tr class="title"> <td align="right">${{total}}           </td> <td>: Total</td> </tr>

              <tr> <td style="height:3px;">                             </td> <td> </td> </tr>
              <tr class="body-2"> <td align="right">{{creditsBack}}     </td> <td>: Credits Back</td> </tr>
            </table>
            <span class="grey--text caption">We give 50% credits back on our processing fee.</span><br />
            <span class="grey--text caption">We expect lower processing fees in the future!</span><br />
          </div>

          <!-- switched to 3D Secure using intents -->
          <!-- <v-btn color="info" @click="paidCheckout">
            Checkout
          </v-btn> -->

        </div>

        <!-- free checkout logged in -->
        <div v-show="total < 0.000001 && loggedIn && numItems != 0" class="text-xs-center">
          <v-btn
            large
            class="mb-3"
            color="info"
            @click="checkout(null)"
          >
            <span v-if="!processing">Create Gifts</span>
            <span v-else>
              <v-progress-circular
                indeterminate
                :size="28"
                color="white"
              ></v-progress-circular>
            </span>
          </v-btn>
        </div>

        <!-- free checkout not logged in -->
        <div v-show="total < 0.000001 && !loggedIn && numItems != 0" class="mt-3 pa-2 grey lighten-3" style="text-align:center;border-radius: 10px;max-width: 500px;margin: auto;">

          <v-layout justify-center>

            <v-flex xs10 sm6 md5>

              <v-text-field
                label="Email (Optional)"
                :placeholder="'bob.smith@gmail.com'"
                v-model="email"
              ></v-text-field>

            </v-flex>

          </v-layout>

          <v-btn
            class="mb-3"
            color="info"
            @click="checkout(null)"
          >

          <span v-if="!processing">Create Gifts</span>
          <span v-else>
            <v-progress-circular
              indeterminate
              :size="28"
              color="white"
            ></v-progress-circular>
          </span>

        </v-btn>

          <v-layout justify-center>

            <v-flex xs10 sm6 md5>

              <h2 class="subheading">Using an email will,</h2>
              <div style="text-align:left;" class="pb-2">
                <ul>
                  <li>Allow you to access your gifts later</li>
                  <li>Let your recepient reply back to your gift email (unless your gift is anonymous)</li>
                </ul>
              </div>

            </v-flex>

          </v-layout>


        </div>

        <!-- Stripe Checkout -->
        <div v-if="total > 0.000001" class="pt-3">
          <checkout-form @submit="(elements) => checkout(elements)"></checkout-form>
        </div>

        <!-- ********************************************* -->
        <!-- old checkout form  -->
        <!-- <div id="card-element"></div>
        <v-btn color="info" @click="intentCheckout">
          Checkout
        </v-btn> -->
        <!-- <v-btn color="info" @click="$store.dispatch('testRequest')">Test Request</v-btn> -->
        <!-- ********************************************* -->

      </v-container>
    </v-content>
  </div>
</template>

<script>
import CheckoutForm from '@/components/checkout/CheckoutForm.vue'

import MainNav from '@/components/navs/MainNav.vue'

import CartItem from '@/components/cart/CartItem.vue'

export default {
  name:'cart',
  components: {
    MainNav,
    CheckoutForm,
    CartItem
  },
  data() {
    return {
    }
  },
  computed: {

    processing() {
      return this.$store.getters.checkoutProcessing;
    },

    loggedIn() { return this.$store.getters.user && this.$store.getters.user.emailVerified; },

    items() { return this.$store.getters.cartItems },

    numItems() {
      let n=0;
      if(this.items) { n = Object.keys(this.items).length; }
      return n;
    },

    startTotal()              { return this.$store.getters.cartStartTotal },
    creditsDeduction()        { return this.$store.getters.cartCreditsDeduction },
    firstGiftTokenDeduction() { return this.$store.getters.cartFirstGiftTokenDeduction },
    afterTotal()              { return this.$store.getters.cartAfterTotal },
    stripeFee()               { return this.$store.getters.cartStripeFee },
    wrapTotal()               { return this.$store.getters.cartWrapTotal },
    processingFee()           { return this.$store.getters.cartProcessingFee },
    total()                   { return this.$store.getters.cartTotal },
    creditsBack()             { return this.$store.getters.cartCreditsBack },

    stripeTotal() { return Math.round(this.total*100) },

    email: {
      get () { return this.$store.getters.checkoutEmail; },
      set (value) { this.$store.commit('setCheckoutEmail', value) }
    },

  },
  methods: {

    checkout(elements) {
      this.$store.dispatch('checkout', elements)  // elements ~ stripe elements
      .catch((error) => this.handleError(error));
    },

    handleError(error) {

      let errorMessage = (error.response) ? error.response.data : error.message;
      switch (errorMessage) {
        case 'FIRST_GIFT_TOKEN_INVALID':
          this.$store.dispatch('showMessage', {type:'error', message:'Invalid Free Gift Token. Token cleared.'});
          this.$store.commit('setFirstGiftToken', null);
          break;

        case 'TANGO_CARDS_SOLD_OUT':
          console.log( 'Tango Cards are sold out.' );
          this.$store.dispatch('showMessage', {type:'error', message:'We\'re sorry, Tango Cards are currently sold out.'});
          break;

        case 'CART_EMPTY':
          console.log( 'Cart Empty' );
          this.$store.dispatch('showMessage', {type:'warning', message:'Cart is empty.'});
          break;

        default:
          console.log( error );
          this.$store.dispatch('showMessage', {type:'error', message:errorMessage});
      }

      this.$store.commit('setCheckoutProcessing', false);
    },

  }
}
</script>
